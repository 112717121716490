import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-error-dialog',
  templateUrl: './error-dialog.component.html',
  styleUrls: ['./error-dialog.component.scss']
})
export class ErrorDialogComponent {
  public errors: any[] = [];

  constructor(
    public dialogRef: MatDialogRef<ErrorDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any | any[]
  ) {
    if (data instanceof Array) {
      this.errors = data;
    } else {
      this.errors = [data];
    }
  }

  get buttonText(): string {
    return this.locData.length > 1 ? 'Dismiss All' : 'Dismiss';
  }

  get locData(): any[] {
    let lastErrorMessage: string;
    let lastErrorCount = 1;
    const retArray: any[] = [];

    this.errors.forEach((err) => {
      if (lastErrorMessage !== undefined) {
        if (lastErrorMessage === err.message) {
          lastErrorCount += 1;
          retArray[retArray.length - 1].count = lastErrorCount;
        } else {
          retArray.push(err);
          lastErrorMessage = err.message;
          lastErrorCount = 1;
        }
      } else {
        retArray.push(err);
        lastErrorMessage = err.message;
      }
    });

    return retArray;
  }
}
