// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  storageBucket: 'gs://iconic-air-dev-eb49d.appspot.com',
  cloudFunctionUrl:
    'https://us-central1-iconic-air-dev-eb49d.cloudfunctions.net',
  firebaseConfig: {
    apiKey: 'AIzaSyBCXnInq759MPFbnzsc83kf7cgsTfeHvmc',
    authDomain: 'iconic-air-dev-eb49d.firebaseapp.com',
    projectId: 'iconic-air-dev-eb49d',
    storageBucket: 'iconic-air-dev-eb49d.appspot.com',
    messagingSenderId: '974005097322',
    appId: '1:974005097322:web:80a76fb8dc675d2a4c4b13',
    measurementId: 'G-YHHSQJFDRC'
  },
  magnumApiUrl: 'https://sf.magnumapi.dev/iconic_air',
  magnumApiKey: 'b7442883-4ca1-4b2c-a645-32f02ea7894d',
  awsS3Config: {
    accessKeyId: 'AKIAUGI7YWX5YKQDRDFW',
    secretAccessKey: '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',
    region: 'us-east-2'
  }
};
