import { Injectable, NgZone } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import {
  AngularFirestore,
  AngularFirestoreCollection,
} from '@angular/fire/firestore';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  CanActivateChild,
  Router,
} from '@angular/router';
import { Company } from '@iconic-air-monorepo/models';
import { combineLatest, from, of, throwError } from 'rxjs';
import { catchError, map, mergeMap } from 'rxjs/operators';
import { AuthenticationWebService } from '../../services/authentication/authentication-web.service';

@Injectable({
  providedIn: 'root',
})
export class PermissionsWebGuard implements CanActivate, CanActivateChild {
  public customers: any[] = [];
  public globalRoles: any;
  public companyPermissions: any;
  public userData: any;
  public userPermissions: any;
  private companiesCollection: AngularFirestoreCollection<Company>;
  public companies: Company[];

  constructor(
    private afs: AngularFirestore,
    private auth: AuthenticationWebService,
    private afAuth: AngularFireAuth,
    private ngZone: NgZone,
    private router: Router
  ) {
    this.companiesCollection = this.afs.collection<Company>('companies');
    this.companiesCollection.valueChanges().subscribe((companies) => {
      this.companies = companies;
    });
  }

  canActivate(route: ActivatedRouteSnapshot): Promise<boolean> {
    return new Promise((resolve, reject) => {
      this.afAuth.authState
        .pipe(
          mergeMap((user) => {
            if (user) {
              // loop through companies get each company doc
              this.auth.userUid = user.uid;
              return from(
                this.afs.collection('users').doc(this.auth.userUid).get()
              ).pipe(
                catchError((error) => {
                  reject();
                  if (error.status === 401 || error.status === 403) {
                    // handle error
                    alert('test');
                  }
                  return throwError(error);
                }),
                mergeMap((userInfo: any) => {
                  this.userData = userInfo.data();
                  const fj = [];
                  const customers: any = userInfo.data().customerIds;
                  const col = this.afs.collection('customers');
                  const queries: any[] = customers.map((el) =>
                    col.doc(el).snapshotChanges()
                  );

                  return combineLatest([...queries]).pipe(
                    catchError((error) => {
                      reject();
                      if (error.status === 401 || error.status === 403) {
                        // handle error
                        alert('test');
                      }
                      return throwError(error);
                    }),
                    map((data: any) => {
                      this.customers = [];
                      for (let cc of data) {
                        if (cc.payload) {
                          this.customers.push({
                            id: cc.payload.id,
                            ...cc.payload.data(),
                          });
                        }
                      }
                    })
                  );
                })
              );
            } else {
              return of(false);
            }
          })
        )
        .subscribe((data) => {
          if (data === false) {
            this.ngZone.run(() => {
              resolve(false);
              this.router.navigate(['/login']);
            });
          } else {
            resolve(true);
          }
        });
    });
  }

  canActivateChild(route: ActivatedRouteSnapshot) {
    // routes = left over routes after all params are done
    const routes = JSON.parse(JSON.stringify(route.url));
    Object.keys(route.params).forEach((param) => {
      const index = routes.findIndex((urlSegment) => {
        return urlSegment.path === route.params[param];
      });

      if (index >= 0) {
        routes.splice(index, 1);
      }
    });

    // check for single falsehood of a route
    if (routes.length) {
      let allParamsCheck = false;

      for (let route of routes) {
        allParamsCheck = this.checkRoute(route.path);
      }

      if (!allParamsCheck) {
        throw 'You do not have access to view this. Please see company admin.';
      }
    }

    return this.checkRoute(route.routeConfig.path);
  }

  private checkRoute = (route: string): boolean => {
    // always allow admins
    if (this.userData.isAdmin) {
      return true;
    }

    if (this.userData?.priviledges?.length) {
      switch (route) {
        case 'admin':
          return this.userData?.isAdmin ? true : false;
        case 'integrations':
          return this.userData?.isAdmin ? true : false;
        case 'facility':
          return this.hasPerms('view_facility') ? true : false;
        case 'reports':
          return this.hasPerms('view_report') ? true : false;
        case 'survey':
          return this.hasPerms('view_survey') ? true : false;

        default:
          return true;
      }
    } else {
      return false;
    }
  };

  public hasPerms(perm: string) {
    // always allow admins
    if (this.userData.isAdmin) {
      return true;
    }

    if (this.userData?.priviledges?.length) {
      const priv = this.userData.priviledges.find((priviledge) => {
        return perm === priviledge;
      });

      if (priv) {
        return true;
      }
    }

    return false;
  }
}
