<div class="dialog-wrapper">
  <h1 mat-dialog-title>Error</h1>
  <div mat-dialog-content>
    <div *ngFor="let d of locData;" class="dialog-row">
      <div *ngIf="d.count" class="p-x-sm p-y">
        <span class="row-count">{{d.count}}</span>
      </div>
      <div *ngIf="d.code" class="p-x-sm p-y">
        <span>{{d.code}}: </span>
        {{d.message}}
      </div>
      <div *ngIf="!d.code && d.message" class="p-x-sm p-y">
        <span class="message">{{d.message}}</span>
      </div>
    </div>
  </div>
  <div mat-dialog-actions class="b-t dialog-actions">
    <button mat-raised-button color="accent" mat-dialog-close class="mat-raised-button mat-accent buttonText">{{buttonText}}</button>
  </div>
</div>
