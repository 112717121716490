import { Component, Input, OnInit } from '@angular/core';
import {
  AngularFirestore,
  AngularFirestoreDocument,
} from '@angular/fire/firestore';
import { Router } from '@angular/router';
import { PermissionsWebGuard } from '../../guards/permissions/permissions-web-guard.guard';
import { User } from '@iconic-air-monorepo/models';
import { AuthenticationWebService } from '../../services/authentication/authentication-web.service';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit {
  public isAdmin = false;
  public navLinks: any[];

  constructor(
    private perms: PermissionsWebGuard,
    private router: Router,
    private authService: AuthenticationWebService,
    private afs: AngularFirestore
  ) {
    this.isAdmin = this.perms.userData?.isAdmin;
    this.navLinks = [
      {
        label: 'Dashboard',
        path: '/dashboard',
      },
      {
        label: 'Sensor Data',
        path: '/sensor-data',
      },
    ];

  //   if (this.perms.hasPerms('view_report')) {
  //     this.navLinks.push({
  //       label: 'Profiles',
  //       path: '/profiles',
  //     });
  //   }

  //   if (this.perms.hasPerms('admin')) {
  //     this.navLinks.push({
  //       label: 'Settings',
  //       path: '/integrations',
  //     });
  //   }
  }

  ngOnInit() {}

  signOut() {
    this.authService.signOut();
  }
}
