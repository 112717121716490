import { HttpErrorResponse } from '@angular/common/http';
import { Injectable, Injector, NgZone } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { ErrorDialogComponent } from '../../components/error-dialog/error-dialog.component';

@Injectable({
  providedIn: 'root',
})
export class ErrorHandlerService {
  private errors: any[] = [];
  private ref: MatDialogRef<ErrorDialogComponent> = null;
  constructor(
    private zone: NgZone,
    private injector: Injector,
    private dialog: MatDialog
  ) {}

  public handleError(error: any) {
    const compError: any = {};
    const router = this.injector.get(Router);
    const zone = this.injector.get(NgZone);

    // compError.message = error.message || error.toString();
    compError.message = 'An unknown error has occurred.';

    if (error instanceof HttpErrorResponse) {
      if (error.error && error.error.message) {
        compError.message = error.error.message;
      }
      compError.code =
        error.status !== undefined ? error.status.toString() : undefined;

      if (error.status === 401) {
        compError.message = 'Session Timed Out';
        zone.run(() => {
          router.navigate(['/login']);
        });
      } else if (error.status === 409) {
        compError.message =
          'Uploaded CSV contains duplicate data. Please try again.';
        compError.code = '';
      } else if (error.status === 503 || error.status === 504) {
        compError.message = 'Service is Currently Unavailable';
      }
    }

    this.addError(compError);
    console.error(error);
  }

  public addError = (err: any): void => {
    this.errors.push(err);
    this.onErrorAdded();
  };

  private onErrorAdded = (): void => {
    if (this.ref) {
      this.ref.componentInstance.data = this.errors;
    } else {
      this.zone.run(() => {
        const unauthorized = this.errors.find((err: any) => {
          return err.code === '401';
        });
        if (unauthorized) {
          // close any remaining dialogs when logged out
          this.dialog.closeAll();
        }
        this.ref = this.dialog.open(ErrorDialogComponent, {
          data: this.errors,
        });

        this.ref.afterClosed().subscribe(() => {
          this.errors = [];
          this.ref = null;
        });
      });
    }
  };
}
