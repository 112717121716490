import { Injectable } from '@angular/core';
import {
  AngularFirestore,
  DocumentData,
  QuerySnapshot,
} from '@angular/fire/firestore';
import {
  ActivatedRouteSnapshot,
  Resolve,
  RouterStateSnapshot,
} from '@angular/router';
import { from, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { PermissionsWebGuard } from '../guards/permissions/permissions-web-guard.guard';
import { Role } from '@iconic-air-monorepo/models';

@Injectable({
  providedIn: 'root',
})
export class RoleResolver implements Resolve<any> {
  constructor(
    private permService: PermissionsWebGuard,
    private afs: AngularFirestore
  ) {}

  public resolve(
    route: ActivatedRouteSnapshot,
    rState: RouterStateSnapshot
  ): Observable<QuerySnapshot<DocumentData>> | boolean {
    return from(this.afs.collection('roles').get()).pipe(
      map((data: any) => {
        const roles = [];
        for (const role of data.docs) {
          roles.push({ id: role.id, ...role.data() });
        }
        this.permService.globalRoles = roles;
        return data;
      })
    );
  }
}
