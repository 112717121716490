import { NgModule } from '@angular/core';
import {
  AngularFireAuthGuard,
  redirectUnauthorizedTo,
} from '@angular/fire/auth-guard';
import { Routes, RouterModule, PreloadAllModules } from '@angular/router';
import { Route } from './shell/shell.service';
import { SignInComponent } from './pages/sign-in/sign-in.component';

const redirectUnauthorizedToLogin = () => redirectUnauthorizedTo(['login']);

const routes: Routes = [
  Route.withShell([
    { path: '', redirectTo: '/dashboard', pathMatch: 'full' },
    {
      path: 'dashboard',
      loadChildren: () =>
        import('./pages/dashboard/dashboard.module').then(
          (m) => m.DashboardModule
        ),
    },
    {
      path: 'facility',
      loadChildren: () =>
        import('./pages/facility/facility.module').then(
          (m) => m.FacilityModule
        ),
    },
    {
      path: 'admin',
      loadChildren: () =>
        import('./pages/admin/admin.module').then((m) => m.AdminModule),
    },
    {
      path: 'profiles',
      loadChildren: () =>
        import('./pages/profile/profile.module').then(
          (m) => m.ProfileSettingsModule
        ),
    },
    {
      path: 'sensor-data',
      loadChildren: () =>
        import('./pages/air-force/table/table.module').then(
          (m) => m.TableModule
        ),
    },
    {
      path: 'base',
      loadChildren: () =>
        import('./pages/air-force/base-page/base-page.module').then(
          (m) => m.BasePageModule
        ),
    },
  ]),
  { path: 'login', component: SignInComponent },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      preloadingStrategy: PreloadAllModules,
      relativeLinkResolution: 'legacy',
    }),
  ],
  exports: [RouterModule],
  providers: [],
})
export class AppRoutingModule {}
