<nav mat-tab-nav-bar>
  <a class="logo">
    <input
      type="image"
      src="../../../assets/test3-logo.png"
      name="Iconic Air Logo"
      id="nav-logo"
      onclick="window.location.href='https://www.iconicair.io'"
    />
  </a>
  <div class="tab-nav">
    <a
      mat-tab-link
      *ngFor="let link of navLinks"
      [routerLink]="link.path"
      routerLinkActive
      #rla="routerLinkActive"
      [routerLinkActiveOptions]="{ exact: true }"
      [active]="rla.isActive"
    >
      {{ link.label }}
    </a>
  </div>

  <div id="user-settings" class="ml-auto">
    <a [matMenuTriggerFor]="menu">
      <mat-icon class="logo">account_circle</mat-icon>
      <span class="username">Team Member</span>
    </a>
    <mat-menu #menu="matMenu">
      <button mat-menu-item [routerLink]="['/admin']" *ngIf="isAdmin">
        <mat-icon>admin_panel_settings</mat-icon>
        <span>Admin</span>
      </button>
      <button mat-menu-item [routerLink]="['/profile']">
        <mat-icon>settings</mat-icon>
        <span>Settings</span>
      </button>
      <mat-divider></mat-divider>
      <button mat-menu-item (click)="signOut()">
        <mat-icon>login</mat-icon>
        <span>Sign Out</span>
      </button>
    </mat-menu>
  </div>
</nav>
