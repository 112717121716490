import { Injectable, NgZone } from '@angular/core';
import { User } from '@iconic-air-monorepo/models';
import { auth } from 'firebase/app';
import { AngularFireAuth } from '@angular/fire/auth';
import { AngularFirestore, AngularFirestoreCollection, AngularFirestoreDocument } from '@angular/fire/firestore';
import { Router } from '@angular/router';
import { connectableObservableDescriptor } from 'rxjs/internal/observable/ConnectableObservable';
import { ConfigurationServicePlaceholders } from 'aws-sdk/lib/config_service_placeholders';
import { from, Observable } from 'rxjs';

@Injectable({
  providedIn:  'root'
})

export class AuthenticationWebService {
  userData: any; // Save logged in user data
  // userEmail: string;
  customerCode: string;

  userDoc: AngularFirestoreDocument<any[]>;
  userFirestore$: Observable<any>;
  userAuth: Observable<User>;

  userUid: string;
  userClientUid: string;

  constructor(
    public afs: AngularFirestore, // Inject Firestore service
    public afAuth: AngularFireAuth, // Inject Firebase auth service
    public router: Router,
    public ngZone: NgZone, // NgZone service to remove outside scope warning
  ) {
    // this.getLoggedInUser();
  }

  // Sign in with email/password working with persistence set to Local
  signIn(email: string, password: string): void {
    this.afAuth.setPersistence(auth.Auth.Persistence.LOCAL)
    .then(() => {
      return this.afAuth.signInWithEmailAndPassword(email, password)
      .then(() => {
        this.ngZone.run(() => {
          this.router.navigate(['/dashboard']);
        });
      })
      .catch((error) => {
        throw(error);
      });
    })
    .catch((error) => {
      // Handle errors here.
      const errorCode = error.code;
      const errorMessage = error.message;
      console.error(errorCode, errorMessage);
    });
  }

  getUser(): Observable<User> {
    return from(this.afAuth.currentUser.then(user => user));
  }

  getUserUid() {
    return this.userUid;
  }

  getUserClientId() {
    return this.userClientUid;
  }

  // Sign up with email/password
  public signUp(email: string, password: string, displayName: string): any {
    return this.afAuth.createUserWithEmailAndPassword(email, password)
      .then((result) => {
        // this updates the display name for the user profile that is created and stored in firebase.
        result.user.updateProfile({
          displayName: displayName,
        });

        // this creates a document in firestore root collection of users
        const userRef: AngularFirestoreDocument<any> = this.afs.doc(`users/${result.user.uid}`);
        const userData: User = {
          uid: result.user.uid,
          email: email,
          displayName: displayName,
          photoURL: result.user.photoURL
        };
        userRef.set(userData, {
          merge: true
        });

        this.sendVerificationMail();
        throw({message: 'Please check your email to verify your account before signing in'});
      })
      .catch((error) => {
        throw(error.message);
      });
  }

  // Send email verfificaiton when new user sign up
  sendVerificationMail(): any {
    // return this.afAuth.sendEmailVerification()
    // .then(() => {
    //   this.router.navigate(['login']);
    // })
  }

  // Reset Forgot password
  public forgotPassword(passwordResetEmail: string): any {
    return this.afAuth.sendPasswordResetEmail(passwordResetEmail)
    .then(() => {
      throw({message: 'Password reset email sent, please check your inbox.'});
    }).catch((error) => {
      throw(error);
    });
  }

  // Returns true when user is looged in and email is verified
  get isLoggedIn(): boolean {
    const user = JSON.parse(localStorage.getItem('user'));
    return (user !== null && user.emailVerified !== false) ? true : false;
  }

  // Sign out
  public signOut(): any {
    return this.afAuth.signOut().then(() => {
      this.router.navigate(['login']);
    });
  }
}
