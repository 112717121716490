import { Component, NgZone, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AngularFireAuth } from '@angular/fire/auth';
import { AuthenticationWebService } from '../../services/authentication/authentication-web.service';


@Component({
  selector: 'app-sign-in',
  templateUrl: './sign-in.component.html',
  styleUrls: ['./sign-in.component.scss']
})

export class SignInComponent implements OnInit {
  public loading = false;
  constructor(
    private auth: AuthenticationWebService,
    private afAuth: AngularFireAuth,
    private router: Router,
    private ngZone: NgZone,
  ) {}

  ngOnInit() {}

  userLogin() {
    const email = (<HTMLInputElement>document.getElementById('inputEmail')).value;
    const password = (<HTMLInputElement>document.getElementById('inputPassword')).value;

    this.loading = true;
    this.auth.signIn(email, password);
  }
}
