<div class="login-page">
  <mat-toolbar>
    <div id="backDiv" onclick="window.location.href='https://www.iconicair.io'">
      <div id="backgroundIcon" onclick="window.location.href='https://www.iconicair.io'">
        <img id="backIcon" src="https://iconic-dev-public.s3.us-east-2.amazonaws.com/coleton-dev/Vector.svg" />
      </div>
      <p id="text">Back Home</p>
    </div>
    <div id="logoDiv">
      <a id="logo-to-homepage">
        <img
          src="https://iconic-dev-public.s3.us-east-2.amazonaws.com/coleton-dev/HD+Transparent+Logo+(1)+(1).png"
          name="Iconic Air Logo" id="nav-logo" onclick="window.location.href='https://www.iconicair.io'" />
      </a>
    </div>
  </mat-toolbar>
  <div class="main">
    <div class="login-form">
      <div class="login-title">
        Login
      </div>
      <div class="login-inner-form">
        <div class="form-field" id="emailAddress">
          <label>Enter Email Address</label>
          <input id="inputEmail" (keydown.enter)="userLogin()" type="text" placeholder="e.g. john@iconicair.io">
        </div>
        <div class="form-field" id="password">
          <label>Enter Password</label>
          <input id="inputPassword" (keydown.enter)="userLogin()" type="password" placeholder="Password">
        </div>
        <div id="loginBottomButton">
          <button mat-flat-button color="primary" class="raiser" (click)="userLogin()">Login</button>
        </div>
        <div>
          <div id="forgotPassword">
            <a routerLink="['/forgot-password']">Forgot Password?</a>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
